import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { SubtitleText, TitleText } from '../common';
const MainSection = () => {
    const [t] = useTranslation();
    return (React.createElement(Container, null,
        React.createElement(Logo, null),
        React.createElement(Trans, { t: t, i18nKey: "main.title", components: [React.createElement(SubtitleText, null), React.createElement(TitleText, null)] })));
};
export { MainSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-otv8bz" }) `
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 60px;

  padding-bottom: 425px;

  background: url('${require('./static/main-screen.webp')}') no-repeat center 1.2vw;
  background-size: contain;

  @media(max-width: 768px) {
    background: url('${require('./static/main-screen-mobile.webp')}') no-repeat center -50px;
    background-size: cover;
    padding-bottom: 300px;
  }
`;
const Logo = styled.div.withConfig({ displayName: "Logo", componentId: "sc-1u48916" }) `
  width: 50px;
  height: 50px;
  margin: 40px 0 12px 0;
  background: url('${require('./static/logo.svg')}') no-repeat center center;
`;
const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-11snfnk" }) `
  margin-top: 57px;
  max-width: 912px;
  z-index: 3;

  > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
