import styled, { css } from "styled-components";
import { color } from "./themes";
export const paragraphTextStyle = css `
  line-height: 140%;
  font-size: 14px;
  font-weight: 400;
  
  color: ${color("textMain")};
  white-space: pre-wrap;
`;
export const ParagraphText = styled.p.withConfig({ displayName: "ParagraphText", componentId: "sc-1obe2fh" }) `
  ${paragraphTextStyle};
`;
