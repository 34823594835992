import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TitleText } from "@socgress/ui";
import { color } from "../themes";
function Alert({ children }) {
    return (React.createElement(Container, null,
        React.createElement(InfoIcon, null),
        React.createElement(TitleText, { style: { fontSize: "14px", lineHeight: "1.4", maxWidth: "674px" } }, children)));
}
Alert.propTypes = {
    type: PropTypes.oneOf(["primary", "error", "success"]),
    children: PropTypes.node,
};
Alert.defaultProps = {
    type: "primary",
    children: null,
};
export { Alert };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-sybsq1" }) `
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin: 8px 0;
  border-radius: 5px;
  background-color: ${color("surfaceBackground")};
`;
const InfoIcon = styled.i.withConfig({ displayName: "InfoIcon", componentId: "sc-15p3n3o" }) `
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-basis: 20px;
  margin-right: 14px;
  mask: url(${require("./info-icon.svg")}) no-repeat 50%;
  mask-size: contain;
  background-color: ${color("surfaceBrand")};
`;
