import { rgba } from "polished";
const colors = {
    surfaceError: '#fb695e',
    textError: '#fb695e',
    surfacePromo: '#FF7438',
    textPromo: '#FF7438',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#6099FA',
    textBrand: '#6099FA',
    textOnBrand: '#FFF',
    surfaceSuccess: '#12C78C',
    textSuccess: '#12C78C',
    surfaceLine: '#2B3946',
    textMain: '#FFF',
    textSecondary: '#AFC0D5',
    surfaceIcon: '#AFC0D5',
    surfaceBorder: '#3F5060',
    surfaceBackground: '#151F28',
    surfaceBackgroundSecondary: '#222F3B',
    surfaceBackgroundAlternate: rgba('#151F28', 0.5),
    surfaceDisabled: '#2B3946',
};
const backgrounds = {
    lightGrey: '#3F5060',
    checkbox: '#fafcff',
    input: '#151F28',
    mobileButton: '#222F3B'
};
const borders = {
    button: '#3F5060',
    checkbox: '#3F5060',
    whiteBlueButton: '#3F5060',
};
const defaultShadow = {
    S: `0px 1px 1px rgba(0, 56, 110, 0.08)`,
    M: '0 5px 15px rgba(0, 0, 0, 0.2)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: '#3F5060',
    backgroundColor: '#222F3B',
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const nightTheme = {
    isDark: true,
    colors,
    backgrounds,
    borders,
    loginBackground: '#151F28',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, nightTheme), { header: whiteHeader });
