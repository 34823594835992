import React from "react";
import PropTypes from "prop-types";
import { List, Item } from "./styled";
function IconList({ list, isRTL }) {
    return (React.createElement("div", null,
        React.createElement(List, null, list.map((item, index) => (React.createElement(Item, { icon: item.icon, key: index, isRTL: isRTL }, item.label))))));
}
const ListShape = PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}));
IconList.propTypes = {
    list: ListShape.isRequired,
};
IconList.defaultProps = {};
export { ListShape, IconList };
