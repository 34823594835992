var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Link, useLocation } from 'react-router-dom';
import useHover from 'react-use-hover';
import { NavigationLink, Icon, Content, Title, OptionalAppend, linkHoverCss, ItemDivider } from './styled';
import styled, { css } from 'styled-components';
import { commonMedia } from '@socgress/lib/common-media';
import { zord } from '@socgress/lib/zord';
import { ifNotProp, theme } from 'styled-tools';
const MenuItem = (_a) => {
    var { icon: MenuIcon, customIcon, children: title, iconColor, titleColor, appendText, onClick, href, replace, rightPaddingDisabled, width, isLinkActive, appendColor, hideArrow, isReverse, hideBackroundOnMobile, as, customIconProps = {}, linkProps = {}, isOwnItem } = _a, componentProps = __rest(_a, ["icon", "customIcon", "children", "iconColor", "titleColor", "appendText", "onClick", "href", "replace", "rightPaddingDisabled", "width", "isLinkActive", "appendColor", "hideArrow", "isReverse", "hideBackroundOnMobile", "as", "customIconProps", "linkProps", "isOwnItem"]);
    const { pathname } = useLocation();
    const isActive = isLinkActive ? isLinkActive(pathname) : pathname === href;
    const [isHovering, hoverProps] = useHover({
        mouseEnterDelayMS: 0,
        mouseLeaveDelayMS: 100,
    });
    return (React.createElement(StyledLink, Object.assign({}, hoverProps, { as: as, hideBackroundOnMobile: hideBackroundOnMobile, to: href, replace: Boolean(replace), customIcon: customIcon, onClick: onClick, href: href }, linkProps),
        React.createElement(NavigationLink, Object.assign({ customIcon: customIcon, className: isActive ? "active" : "" }, componentProps),
            (MenuIcon || customIconProps.src) && (!isReverse && (customIcon && typeof MenuIcon !== 'string' ? React.createElement(MenuIcon, Object.assign({ isHovering: isHovering }, customIconProps)) : (React.createElement(Icon, { size: width, isNotMask: customIconProps.isNotMask, iconSrc: customIconProps.src || MenuIcon, color: iconColor })))),
            React.createElement(Content, { hideArrow: hideArrow, rightPaddingDisabled: rightPaddingDisabled },
                React.createElement(Title, { color: titleColor }, title),
                appendText && (React.createElement(OptionalAppend, { color: appendColor }, appendText))),
            isReverse && (customIcon ? React.createElement(MenuIcon, { isHovering: isHovering }) : React.createElement(Icon, { size: width, isReverse: isReverse, iconSrc: MenuIcon, color: iconColor })))));
};
const DesktopMenuItem = (props) => {
    return (React.createElement(DesktopMenuItemContainer, null,
        React.createElement(MenuItem, Object.assign({}, props, { width: 18 }))));
};
const AnchorMenuLink = (_a) => {
    var { icon, children: title, iconColor, titleColor, appendText, onClick, href, size } = _a, componentProps = __rest(_a, ["icon", "children", "iconColor", "titleColor", "appendText", "onClick", "href", "size"]);
    return (React.createElement(NavigationLink, Object.assign({ href: href, onClick: onClick }, componentProps),
        React.createElement(Icon, { size: size, iconSrc: icon, color: iconColor }),
        React.createElement(Content, { rightPaddingDisabled: false },
            React.createElement(Title, { color: titleColor }, title),
            appendText && React.createElement(OptionalAppend, null, appendText))));
};
DesktopMenuItem.defaultProps = MenuItem.defaultProps = AnchorMenuLink.defaultProps = {
    icon: null,
    iconColor: null,
    titleColor: null,
    appendText: null,
    onClick: () => { },
};
export { MenuItem, DesktopMenuItem, AnchorMenuLink };
const DesktopMenuItemContainer = styled.div.withConfig({ displayName: "DesktopMenuItemContainer", componentId: "sc-rpnj1y" }) `
  
`;
export const StyledLink = styled(Link).withConfig({ displayName: "StyledLink", componentId: "sc-1hr1e3d" }) `
  display: flex;
  align-items: center;
  position: relative;
  
  @media(max-width: 768px) {
    ${ifNotProp('hideBackroundOnMobile', css `
      background: ${theme('backgrounds.mobileButton')};
    `)};
  }
  
  ${NavigationLink} {
    background: transparent;
  }

  ${p => p.zord ? css `
    background-color: black !important;
    
    &:last-child {
      & > ${NavigationLink} {
        margin-bottom: 0;
      }
    }
  ` : ''};
    
  ${commonMedia.greaterThan('tablet') `
    &:hover {
      & > div {
        ${p => linkHoverCss(!p.customIcon)};
      }
    }
  `};

  ${commonMedia.lessThan("tablet") `
    ${ItemDivider};
    padding: ${zord(4)};
  `};
`;
