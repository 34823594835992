import { useIsTablet } from '@socgress/lib/common-media';
import { zord } from '@socgress/lib/zord';
import { color } from '@socgress/ui/themes';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import { SubtitleText, TitleButton, TitleText } from '../common/atoms';
import { useTranslation } from 'react-i18next';
const PanelLandingSection = () => {
    const [t] = useTranslation();
    const isTablet = useIsTablet();
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(HeadContainer, null,
                React.createElement(TitleButton, null, t('panel_landing.title_button')),
                React.createElement(TitleText, null, t('panel_landing.title')),
                React.createElement(SubtitleText, null, t('panel_landing.text'))),
            React.createElement(ContentContainer, null, ['panel', 'landing'].map((type) => (React.createElement("div", { key: `${type}-content` },
                React.createElement(ImageContainer, { type: type },
                    React.createElement("img", { src: require(`./static/${type}${isTablet ? '-mobile' : ''}.${isTablet ? 'webp' : 'png'}`), alt: "" })),
                React.createElement(TextContainer, { rightAlign: type === 'landing', type: type },
                    React.createElement(Title, null, t(`panel_landing.features.${type}.title`)),
                    React.createElement(Text, null, t(`panel_landing.features.${type}.text`)),
                    React.createElement(ButtonsContainer, { revert: type === 'landing' },
                        React.createElement(Button, { href: "https://socpanel.com", target: "_blank" }, t(`panel_landing.features.${type}.button`)),
                        React.createElement(PinkButton, null,
                            React.createElement("img", { src: require('./static/star.svg'), alt: "" }),
                            t(`panel_landing.features.${type}.types_count`)))),
                (type === 'panel') && (React.createElement(BottomTextContainer, null,
                    React.createElement(BottomTextIcon, null),
                    t(`panel_landing.features.${type}.bottom_text`))))))))));
};
export { PanelLandingSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-i2hlk5" }) `
  margin-top: 195px;
  width: 100%;
  background: url(${require('./static/services.webp')}) no-repeat center;
  background-size: 75%;

  & > div {
    margin: 0 auto;
    max-width: 1240px;
  }

  @media(max-width: 768px) {
    margin-top: 77px;
    max-width: 100%;
    background: none;
  }
`;
const HeadContainer = styled.div.withConfig({ displayName: "HeadContainer", componentId: "sc-1xrs6j0" }) `
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 99;
  position: relative;
`;
const ContentContainer = styled.div.withConfig({ displayName: "ContentContainer", componentId: "sc-zhvqnm" }) `
  margin-top: 80px;
  display: flex;

  > div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    margin-top: 72px;
  }
`;
const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-92smen" }) `
  position: relative;
  width: 100%;
  visibility: hidden;

  > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  ${switchProp('type', {
    landing: css `
      > img {
        z-index: 4;
      }
    `,
})};

  @media (max-width: 768px) {
    visibility: visible;
    margin-top: -200px;
  }
`;
const ButtonsContainer = styled.div.withConfig({ displayName: "ButtonsContainer", componentId: "sc-1nhz86m" }) `
  display: flex;
  align-items: center;
  gap: ${zord(3)};
  margin-top: ${zord(6)};

  ${ifProp('revert', css `
    flex-direction: row-reverse;
  `)};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    & > :last-child {
      display: none;
    }
  }
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1usl20b" }) `
  position: relative;
  z-index: 6;

  ${switchProp('type', {
    panel: css `
      margin-left: 100px;
    `,
    landing: css `
      text-align: right;
      margin-right: 130px;
    `,
})};

  @media(max-width: 768px) {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;
    margin-top: -80px;
  }
`;
const Title = styled.h5.withConfig({ displayName: "Title", componentId: "sc-1wel2gb" }) `
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: ${color('textOnBrand')};

  @media(max-width: 768px) {
    font-size: 20px;
  }
`;
const Text = styled.div.withConfig({ displayName: "Text", componentId: "sc-1bcn6pl" }) `
  margin-top: ${zord(4)};
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #8599A3;

  @media(max-width: 768px) {
    font-size: 16px;
  }
`;
const BottomTextContainer = styled.div.withConfig({ displayName: "BottomTextContainer", componentId: "sc-xrw5x6" }) `
  display: flex;
  align-items: center;
  margin-top: 150px;
  color: #8599A3;

  @media(min-width: 769px) {
    margin-left: 100px;
  }

  @media(max-width: 768px) {
    display: none;
  }
`;
const BottomTextIcon = styled.div.withConfig({ displayName: "BottomTextIcon", componentId: "sc-p7dlcn" }) `
  width: 20px;
  height: 20px;
  margin-right: 5px;
  mask: url(${require('./static/icon.svg')}) no-repeat;
  mask-size: contain;
  background: #8599A3;
`;
const Button = styled.a.withConfig({ displayName: "Button", componentId: "sc-16jrw4n" }) `
  display: flex;
  align-items: center;
  padding: 11px 25px;
  border: 1px solid ${color('textBrand')};
  box-shadow: ${theme('shadow.S')};
  border-radius: 10px;
  color: ${color('textBrand')};
  cursor: pointer;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  &:hover {
    background-color: ${color('surfaceBrand')};
    color: ${color('textOnBrand')};
  }
`;
const PinkButton = styled(Button).withConfig({ displayName: "PinkButton", componentId: "sc-k5rz5u" }) `
  padding: 11px 16px 11px 12px;
  gap: 5px;
  border: 1px solid #F6B6F7;
  border-radius: 12px;
  text-transform: uppercase;
  background: linear-gradient(90.21deg, #F8ACFA 10%, #F0AFBF 58.73%, #FFE2E7 95.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: default;

  > img {
    width: 20px;
    height: 20px;
  }
`;
