import styled, { css } from "styled-components";
import { color } from "./themes";
export const bigTextStyle = css `
  font-weight: 500;
  font-size: 27px;

  color: ${color("textMain")};
  white-space: pre-wrap;
`;
export const BigText = styled.p.withConfig({ displayName: "BigText", componentId: "sc-pxo45l" }) `
  ${bigTextStyle};
`;
