import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import { I18nextProvider } from 'react-i18next/dist/es/I18nextProvider';
import i18next from './lib/i18n';
import { ThemeProvider } from 'styled-components';
import { nightTheme } from '@socgress/ui/themes';
import { GlobalStyle } from '@socgress/lib/global-style';
import { merge } from 'lodash';
import App from './features/app';
if (process.env.NODE_ENV === 'development') {
    const consoleError = console.error.bind(console);
    console.error = (...args) => {
        const excludedErrors = [/React does not recognize/, /Received `.+` for/];
        if (!args.some(item => excludedErrors.some(item2 => item2.test(item)))) {
            consoleError(...args);
        }
    };
}
const rootElement = document.getElementById('app');
const BotsLanding = () => {
    const customTheme = {
        globalBackground: '#000D13',
        colors: {
            textSecondary: '#9FA2AE',
        },
    };
    const theme = merge(nightTheme, customTheme);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(GlobalStyle, null),
        React.createElement(App, null)));
};
ReactDOM.render(React.createElement(I18nextProvider, { i18n: i18next },
    React.createElement(BotsLanding, null)), rootElement);
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}
