import styled from 'styled-components';
import { color } from '@socgress/ui/themes';
import { zord } from '@socgress/lib/zord';
const SubtitleText = styled.p.withConfig({ displayName: "SubtitleText", componentId: "sc-e1w6gz" }) `
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: ${color('textSecondary')};
`;
export { SubtitleText };
const TitleText = styled.h2.withConfig({ displayName: "TitleText", componentId: "sc-ndbaj5" }) `
  font-weight: 700;
  font-size: 50px;
  line-height: 90%;
  background: linear-gradient(0deg, #FFFFFF -6.67%, rgba(255, 255, 255, 0.35) 130%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 6px; // prevent letters crop

  & + ${SubtitleText} {
    margin-top: ${zord(4)};
  }

  @media(max-width: 768px) {
    font-size: 30px;

    & + ${SubtitleText} {
      display: none;
    }
  }
`;
export { TitleText };
const SecondSubtitleText = styled.p.withConfig({ displayName: "SecondSubtitleText", componentId: "sc-v3s5yr" }) `
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #8599A3;

  @media(max-width: 768px) {
    font-size: 16px;
  }
`;
export { SecondSubtitleText };
const SecondTitleText = styled.h5.withConfig({ displayName: "SecondTitleText", componentId: "sc-1osjjcs" }) `
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -.01em;
  color: ${color('textOnBrand')};
  opacity: .9;

  & + ${SecondSubtitleText} {
    margin-top: ${zord(2)};
  }

  @media(max-width: 768px) {
    font-size: 20px;
  }
`;
export { SecondTitleText };
const TitleButton = styled.div.withConfig({ displayName: "TitleButton", componentId: "sc-1yffao" }) `
  display: inline-flex;
  align-items: center;
  background: linear-gradient(90.21deg, #8590F6 10%, #E2E5FF 95.06%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-family: Menlo, sans-serif;
  font-size: 12px;
  line-height: 90%;
  margin-bottom: ${zord(5)};
  padding: 12px 16px 10px 16px;
  border: 1px solid #16182E;
  border-radius: 500px;
  text-transform: uppercase;
`;
export { TitleButton };
const ArrowLink = styled.a.withConfig({ displayName: "ArrowLink", componentId: "sc-oqwxga" }) `
  padding-right: ${zord(6)};
  background: url('${require('./../static/arrow.svg')}') no-repeat right calc(50% + 1px);
  color: ${color('textBrand')};
  cursor: pointer;
`;
export { ArrowLink };
