import { rgba } from 'polished';
const colors = {
    surfaceError: '#fb695e',
    textError: '#fb695e',
    surfacePromo: '#FF7438',
    textPromo: '#FF7438',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#7A44BF',
    textBrand: '#7A44BF',
    textOnBrand: '#FFFFFF',
    surfaceSuccess: '#12C78C',
    textSuccess: '#12C78C',
    surfaceLine: '#F5EFFA',
    textMain: '#1F0038',
    textSecondary: '#7A5995',
    surfaceIcon: '#C0A4D8',
    surfaceBorder: '#E0CEEF',
    surfaceBackground: '#EFE5F8',
    surfaceBackgroundSecondary: '#FDFBFF',
    surfaceBackgroundAlternate: rgba('#F7EFFA', 0.5),
    surfaceDisabled: '#866FA4',
};
const defaultShadow = {
    S: `0px 1px 1px rgba(0, 56, 110, 0.08)`,
    M: '0 5px 15px rgba(0, 68, 133, 0.05)',
    L: '0px 10px 30px rgba(0, 68, 133, 0.15)',
};
const backgrounds = {
    lightGrey: '#EFF4FA',
    checkbox: '#fafcff',
    input: '#fff',
    mobileButton: 'linear-gradient(to right, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.04) 100%)'
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: '#cedef2',
    whiteBlueButton: '#E1EDFA',
};
const defaultHeader = {
    bgColor: colors.surfaceBackgroundSecondary,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackgroundSecondary,
    menuButtonColor: colors.surfaceBackgroundSecondary,
    activeMenuButtonColor: colors.surfaceBackgroundSecondary,
    desktopMenuBg: colors.surfaceBackgroundSecondary,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: '#DBE7F2',
    backgroundColor: '#fff',
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackgroundSecondary });
export const lightVioletTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#fff',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: '1224px',
    },
};
