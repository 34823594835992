import styled, { css } from "styled-components";
import { theme } from "styled-tools";
import { zord } from "@socgress/lib/zord";
import { borderRadius, color } from '@socgress/ui/themes/color';
export const WhiteCardStyle = css `
  border-radius: ${borderRadius()}px;
  background-color: ${color('surfaceBackgroundSecondary')};
  box-shadow: 0 1px 1px rgba(0, 56, 110, 0.08);
  
  & + & {
    margin-top: ${zord(4)};
  }
`;
export const WhiteCard = styled.div.withConfig({ displayName: "WhiteCard", componentId: "sc-tdnkhh" }) `
  ${WhiteCardStyle};
`;
export const TableTransparentCard = styled.div.withConfig({ displayName: "TableTransparentCard", componentId: "sc-14sgixv" }) `
`;
export const TableWhiteCard = styled(TableTransparentCard).withConfig({ displayName: "TableWhiteCard", componentId: "sc-17xpht1" }) `
  ${WhiteCardStyle};
  overflow: auto;
  overflow-x: scroll;
`;
export const hoverableCardCss = css `
  transition: box-shadow 0.15s ease-in-out;

  &:hover {
    box-shadow: ${theme("shadows.S")};
  }
`;
