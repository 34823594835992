import { Desktop, Tablet } from '@socgress/lib/media';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LocaleSelect } from './locale-select';
import MobileLogo from './mobile-logo.svg';
function Header() {
    const [t] = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(Desktop, null,
            React.createElement(Container, null,
                React.createElement(PageLinksContainer, null),
                React.createElement(FlexContainer, null,
                    React.createElement(LocaleSelect, null),
                    React.createElement(Button, { href: "/login" },
                        React.createElement(ButtonLink, null, t('header.login')))))),
        React.createElement(Tablet, null,
            React.createElement(Container, null,
                React.createElement("img", { src: MobileLogo, alt: "mobile logo" }),
                React.createElement("div", null,
                    React.createElement(Button, { href: "/login" }, t('header.login')))))));
}
export { Header };
const FlexContainer = styled.div.withConfig({ displayName: "FlexContainer", componentId: "sc-1se0cor" }) `
  display: flex;
  align-items: center;
  gap: 10px;
`;
const HeaderLink = styled.p.withConfig({ displayName: "HeaderLink", componentId: "sc-1ob1a17" }) `
  cursor: pointer;
`;
const Container = styled.header.withConfig({ displayName: "Container", componentId: "sc-1qrqevo" }) `
  width: 100%;
  padding: 21px 108px;

  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 21px 15px;
  }
`;
const Button = styled.div.attrs({
    as: 'a',
}).withConfig({ displayName: "Button", componentId: "sc-1j8i1ko" }) `
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  border-radius: 5px;
  position: relative;
  padding: 11px 24px;

  transition: background-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s;

  &:hover {
    box-shadow: rgb(0 56 110 / 8%) 0 0 15px 5px,
      rgb(94 152 223) 0 0 0 1px inset;
    background-color: rgb(94, 152, 223);

    & > a {
      color: #fff;
    }
  }
`;
const ButtonLink = styled.a.withConfig({ displayName: "ButtonLink", componentId: "sc-c755oq" }) `
  display: block;

  transition: color 0.2ms ease;

  @media (max-width: 768px) {
    padding: 11px 24px;
  }
`;
const PageLinksContainer = styled.div.withConfig({ displayName: "PageLinksContainer", componentId: "sc-ttg3gn" }) `
  padding: 10px 0;
  display: flex;

  & > :not(:first-child) {
    margin-left: 30px;
  }
`;
const SwitchContainer = styled.div.withConfig({ displayName: "SwitchContainer", componentId: "sc-qgxd9z" }) `
  display: flex;
  align-items: center;
  gap: 10px;

  @media(min-width: 769px) {
    margin-right: 1.5vw;
  }

  span {
    background: rgba(255, 255, 255, 0.2);

    &:before {
      width: 16px;
      height: 16px;
      left: 5px;
      bottom: 5px;
      background: linear-gradient(136.27deg, #FFFFFF 14.1%, #C7CBDF 22.85%, #C3C7DB 27.55%, #FFFFFF 34.84%, #BEC1D3 49.11%, #B5B5DF 54.69%, #FFFFFF 75.94%, #B7BBD0 84.44%);
      box-shadow: -0.2px 0.5px 0 #7E9EB6, inset 0 0.5px 0.1px rgba(255, 255, 255, 0.6);
    }
  }
`;
const SwitchLabel = styled.p.withConfig({ displayName: "SwitchLabel", componentId: "sc-1ad54ge" }) `
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;

  color: #FFFFFF;

`;
