import { formatNumberAbbr } from '@socgress/lib/helpers';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { zord } from '@socgress/lib/zord';
import { useInView, animate } from 'framer-motion/dist/framer-motion';
import { switchProp } from 'styled-tools';
import { TitleText, SubtitleText } from '../common';
import { useTranslation } from 'react-i18next';
import { ArrowLink } from '../common/atoms';
const useAnimatedValue = (isInView, to) => {
    const [animatedValue, setAnimatedValue] = useState(0);
    useEffect(() => {
        if (!isInView) {
            return;
        }
        const animatedControls = animate(0, to, {
            duration: 3,
            onUpdate: (latestValue) => setAnimatedValue(latestValue),
        });
        return () => animatedControls.stop();
    }, [isInView, to]);
    return formatNumberAbbr(animatedValue.toFixed(0));
};
const { stats } = window.__PRELOADED_STATE__ || {};
const CountersSection = () => {
    const [t] = useTranslation();
    const countersContainerRef = useRef();
    const isInView = useInView(countersContainerRef, { once: true });
    const resellersValue = useAnimatedValue(isInView, (stats === null || stats === void 0 ? void 0 : stats.panels) || 24000);
    const tasksValue = useAnimatedValue(isInView, (stats === null || stats === void 0 ? void 0 : stats.completions) || 12000000);
    return (React.createElement(Container, null,
        React.createElement(TextContainer, null,
            React.createElement(TitleText, null, t('counters.title')),
            React.createElement(SubtitleText, null, t('counters.text'))),
        React.createElement(CountersContainer, { ref: countersContainerRef }, ['resellers', 'tasks'].map((type) => (React.createElement(Counter, null,
            React.createElement(CountText, { type: type }, type === 'resellers' ? resellersValue : tasksValue),
            React.createElement(CounterText, null, t(`counters.counters.${type}`)),
            React.createElement(CounterLink, { href: "/register" }, t('counters.explore'))))))));
};
export { CountersSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-zcguqf" }) `
  padding: ${zord(0, 4)};
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-dkptje" }) `
  margin-top: ${zord(4)};
  text-align: center;
`;
const CountText = styled.h3.withConfig({ displayName: "CountText", componentId: "sc-12z0xhf" }) `
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 100px;
  line-height: 90%;

  letter-spacing: -0.02em;
  text-transform: uppercase;

  background: ${switchProp('type', {
    resellers: 'linear-gradient(181.2deg, #DBF0FC 9.73%, #497EAF 71.09%, #133356 98.97%)',
    tasks: 'linear-gradient(181.27deg, #FBDBFC 9.24%, #9B49AF 48.92%, #2C1B72 98.91%)',
})};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const CountersContainer = styled.div.withConfig({ displayName: "CountersContainer", componentId: "sc-1nh1l47" }) `
  display: flex;
  align-items: center;
  gap: ${zord(10)};
  margin-top: ${zord(10)};
  text-align: center;
  letter-spacing: -0.02em;

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
  }
`;
const Counter = styled.div.withConfig({ displayName: "Counter", componentId: "sc-1lf9ka2" }) `
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const CounterText = styled.div.withConfig({ displayName: "CounterText", componentId: "sc-1wpkt3c" }) `
  margin-top: ${zord(4)};
  font-weight: 700;
  font-size: 27px;
  line-height: 110%;

  @media(max-width: 768px) {
    font-size: 20px;
  }
`;
const CounterLink = styled(ArrowLink).withConfig({ displayName: "CounterLink", componentId: "sc-3v3h08" }) `
  margin-top: ${zord(6)};
`;
