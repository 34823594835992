import styled, { css } from 'styled-components';
import { ifNotProp, prop } from 'styled-tools';
import { commonMedia } from "@socgress/lib/common-media";
import { color } from "../themes";
import { zord } from "@socgress/lib/zord";
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-b6q259" }) `
  position: relative;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1uff8kg" }) `
  overflow: auto;
  padding: ${zord(5)};

  ${commonMedia.lessThan("tablet") `
    border-bottom: 1px solid ${color('surfaceLine')};
    background: ${color("surfaceBackgroundSecondary")} url(${prop("tabletImage")})
      no-repeat 100%;
    background-size: contain;
  `};
`;
export const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-18683on" }) `
  padding: ${zord(5)};
  display: flex;
  justify-content: space-between;
  //position: relative;

  ${ifNotProp('lottieHideOverflow', css `overflow: hidden;`)};
  
  @media (max-width: 768px) {
    //max-width: ${(props) => `${props.bodyMaxWidth}%`};
    padding-top: ${zord(5)};
  }
`;
