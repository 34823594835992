import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediumTextStyle } from '@socgress/ui/medium-text';
import { color } from '@socgress/ui/themes';
import { MainText } from '@socgress/ui/main-text';
export const LinkText = styled(MainText).attrs(props => ({
    as: props.as || 'a'
})).withConfig({ displayName: "LinkText", componentId: "sc-109zbpb" }) `
  cursor: pointer;
  color: ${color('textBrand')};
  
  &[disabled] {
    color: ${color('surfaceDisabled')};
  }
`;
export const RouterLinkText = styled(Link).withConfig({ displayName: "RouterLinkText", componentId: "sc-1wbselg" }) `
  ${mediumTextStyle};
  color: ${color('textBrand')};
`;
