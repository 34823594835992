export const sidebarIcons = {
    dashboard: '/static/icons/icons/dashboard.svg',
    trash: '/static/icons/icons/bin-icon.svg',
    menu: require('@socgress/ui/header/icons/imgs/menu.svg'),
    bolt: require('@socgress/ui/header/icons/imgs/bolt.svg'),
    blog: '/static/icons/icons/blog.svg',
    api: '/static/icons/icons/api.svg',
    cancel: '/static/icons/icons/cancel.svg',
    copy: '/static/icons/icons/copy.svg',
    filters: '/static/icons/icons/filter.svg',
    myOrders: '/static/icons/icons/my-orders.svg',
    news: '/static/icons/icons/news.svg',
    referral: '/static/icons/icons/referral.svg',
    refill: '/static/icons/icons/refill.svg',
    search: '/static/icons/icons/search.svg',
    notifications: require('@socgress/ui/header/icons/imgs/bell.svg'),
    services: '/static/icons/icons/services.svg',
    settings: '/static/icons/icons/settings.svg',
    favoriteAdd: '/static/icons/icons/favorite-add.svg',
    favoriteRemove: '/static/icons/icons/favorite-remove.svg',
    balanceHistory: '/static/icons/icons/balance-history.svg',
    promoCode: '/static/icons/icons/promocode.svg',
    info: '/static/icons/icons/info.svg',
    telegram: '/static/icons/icons/telegram.svg',
    support: '/static/icons/icons/support.svg',
    supportSubmit: '/static/icons/icons/send.svg',
    massOrder: '/static/icons/icons/mass-order.svg',
    newOrder: '/static/icons/icons/add-plus.svg',
    coins: '/static/icons/icons/coins.svg',
    dripFeed: require('@socgress/ui/header/icons/imgs/providers.svg'),
};
export const sidebarBusinessIcons = Object.assign(Object.assign({}, sidebarIcons), { dashboard: '/static/icons/icons/dashboard.svg', blog: '/static/icons/business-icons/blog.svg', notifications: '/static/icons/business-icons/bell.svg', api: '/static/icons/business-icons/api.svg', cancel: '/static/icons/icons/cancel.svg', copy: '/static/icons/icons/copy.svg', filters: '/static/icons/business-icons/filter.svg', myOrders: '/static/icons/business-icons/my-orders.svg', news: '/static/icons/business-icons/news.svg', referral: '/static/icons/business-icons/referrals.svg', refill: '/static/icons/icons/refill.svg', search: '/static/icons/business-icons/search.svg', services: '/static/icons/business-icons/services.svg', settings: '/static/icons/business-icons/settings.svg', favoriteAdd: '/static/icons/icons/favorite-add.svg', favoriteRemove: '/static/icons/icons/favorite-remove.svg', balanceHistory: '/static/icons/icons/balance-history.svg', promoCode: '/static/icons/icons/promocode.svg', info: '/static/icons/icons/info.svg', telegram: '/static/icons/icons/telegram.svg', support: '/static/icons/business-icons/support.svg', supportSubmit: '/static/icons/icons/send.svg', massOrder: '/static/icons/business-icons/multiple.svg', newOrder: '/static/icons/business-icons/plus.svg', wallet: '/static/icons/business-icons/wallet.svg', coins: '/static/icons/business-icons/coins.svg' });
export const friendlyIcons = Object.assign(Object.assign({}, sidebarIcons), { notifications: '/static/icons/friendly/notifications.svg', blog: '/static/icons/friendly/blog.svg', api: '/static/icons/friendly/api.svg', newOrder: '/static/icons/friendly/new-order.svg', massOrder: '/static/icons/friendly/mass-order.svg', myOrders: '/static/icons/friendly/cart.svg', settings: '/static/icons/friendly/settings.svg', support: '/static/icons/friendly/support.svg', services: '/static/icons/friendly/services.svg' });
export const usePanelIcon = (theme) => {
    const getPanelIconForTheme = (iconCode, defaultIcon) => getPanelIcon(iconCode, defaultIcon)({ theme });
    return { getPanelIcon: getPanelIconForTheme };
};
export const getPanelIcon = (iconCode, defaultIcon) => {
    return ({ theme }) => {
        if (theme.icons && theme.icons[iconCode]) {
            return theme.icons[iconCode] || defaultIcon;
        }
        if (theme.variant === 'accessible') {
            return sidebarIcons[iconCode] || defaultIcon;
        }
        if (theme.variant === 'business') {
            return sidebarBusinessIcons[iconCode] || defaultIcon;
        }
        if (theme.variant === 'friendly') {
            return friendlyIcons[iconCode] || defaultIcon;
        }
        return defaultIcon;
    };
};
