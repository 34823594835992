export function color(name) {
    return ({ theme }) => {
        return theme.colors[name];
    };
}
export function borderRadius(defaultBorder = 10) {
    return ({ theme }) => {
        return typeof theme.borderRadius === 'undefined' ? defaultBorder : theme.borderRadius;
    };
}
