import { createGlobalStyle } from "styled-components";
import { color } from "@socgress/ui/themes";
// @ts-ignore
export const GlobalStyle = createGlobalStyle `
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body.ReactModal__Body--open {
    -webkit-overflow-scrolling: touch;
    
    #app {
      user-select: none;
    }
  }

  .react-modal-sheet-backdrop {
    touch-action: none;
  }

  .react-modal-sheet-content {
    background-color: ${color('surfaceBackground')};
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
  }

  .hot_toast {
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    color: ${color('textMain')};
    bottom: 60px;
    padding: 10px 15px;
    background-color: ${color('surfaceBackgroundSecondary')};
  }

  html, body {
      margin: 0;
      padding: 0;
    }


  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 100%;
    min-height: 100%;
  }
  
  pre {
    white-space: pre-wrap;
  }

  body {
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    color: ${color("textMain")};
    margin: 0;
    background: ${(props) => props.background || props.theme.globalBackground};
    position: relative;
    min-width: 1040px;

    &.landing {
      min-width: 1240px;
      background: ${color('surfaceBackgroundSecondary')};;

      @media (max-width: 768px) {
        min-width: auto;
      }
    }

    &.partner {
      background: #f5f8fc;
    }

    &.ad {
      font-family: "IBM Plex Sans", sans-serif;
      background: #F5F8FC;
    }

    @media (max-width: 768px) {
      min-width: auto;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  // TODO: replace Chrome focus outline for accessibility
  
  //:focus {
  //  outline: 0;
  //}

  p {
    margin: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  ol,
  ul {
    margin: 0;
    //list-style: none;
    padding: 0;
  }

  table {
    border-collapse: collapse;
  }

  input, button, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  textarea {
    overflow: auto;
    resize: vertical;
    appearance: none
  }

  a, button, input, label, select, textarea {
    touch-action: manipulation;
  }

  input {
    appearance: none
  }

  input {
    outline-color: ${color('surfaceBrand')};
  }
  
  a,
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    cursor: pointer;
  }

  button, input {
    overflow: visible;
    padding: 0;
  }

  button, select {
    text-transform: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: initial;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }




  &.fadeIn-enter {
    opacity: 0;
  }

  &.fadeIn-enter.fadeIn-enter-active {
    opacity: 1;
    transition: opacity 0.175s ease-in-out;
  }

  &.fadeIn-exit {
    opacity: 1;
  }

  &.fadeIn-exit.fadeIn-exit-active {
    opacity: 0;
    transition: opacity 0.175s ease-in-out;
  }

  &.fadeInDown-enter {
    opacity: 0;
    //transform: translate3d(0, -50px, 0);
  }

  &.fadeInDown-enter.fadeInDown-enter-active {
    opacity: 1;
    //transform: translate3d(0, 0, 0);
    transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
  }

  &.fadeInDown-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.fadeInDown-exit.fadeInDown-exit-active {
    //transform: translate3d(0, -50px, 0);
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    /* height: 100%; */
    position: relative;
    transition-property: transform;
  }
  .swiper-invisible-blank-slide {
    visibility: hidden;
  }
  /* Auto Height */
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }

  .nanobar {
    width: 100%;
    height: 4px;
    z-index: 9999;
    top: 0;
  }

  .bar {
    width: 0;
    height: 100%;
    transition: height 0.3s;
    background-color: rgb(178, 221, 255);
  }

  .nanobar .bar {
    visibility: hidden;
  }

  .nanobar .bar:first-child {
    visibility: visible;
  }
  
  
  /* roboto-regular - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
   src: url('/static/fonts/roboto-v20-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */

  src: local('Roboto'), local('Roboto-Regular'),
       url('/static/fonts/roboto-v20-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-v20-cyrillic_latin-regular.eot') format('embedded-opentype'); /* IE6-IE8 */

}

/* roboto-500 - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/roboto-v20-cyrillic_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('/static/fonts/roboto-v20-cyrillic_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-500.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-v20-cyrillic_latin-500.eot') format('embedded-opentype');
       /* IE6-IE8 */
}

/* roboto-700 - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/roboto-v20-cyrillic_latin-700.eot'); /* IE9 Compat Modes */

  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('/static/fonts/roboto-v20-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-v20-cyrillic_latin-700.eot') format('embedded-opentype'); /* IE6-IE8 */
}

/* roboto-900italic - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('/static/fonts/roboto-v20-cyrillic_latin-900italic.eot'); /* IE9 Compat Modes */

  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
       url('/static/fonts/roboto-v20-cyrillic_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-v20-cyrillic_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-v20-cyrillic_latin-900italic.eot') format('embedded-opentype'); /* IE6-IE8 */
}
`;
