var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { lighten, darken, getLuminance } from 'polished';
import isFunction from 'lodash/isFunction';
import styled, { css, keyframes, ThemeProvider, useTheme } from 'styled-components';
import { color, defaultTheme } from '@socgress/ui/themes';
import { zord } from '@socgress/lib/zord';
import { ifProp, prop, theme } from 'styled-tools';
import { useFormikContext } from 'formik';
const getHoverColorByThemeColor = (color) => {
    return getLuminance(color) > 0.7 ? darken(0.03, color) : darken(0.02, color);
};
const getHoverColorBySiteThemeColor = (siteColor) => ({ theme }) => getHoverColorByThemeColor(theme.colors[siteColor]);
const themes = {
    green: '#12c78c',
    gray: '#fff',
    vk: '#5e98df',
    instagram: '#a772db',
    youtube: '#f6543f',
    twitter: '#22aafb',
    ok: '#ee8208',
    telegram: '#4BAEFA',
    blue: {
        bgColor: color('surfaceBrand'),
        textColor: color('textOnBrand'),
        hoverTextColor: getHoverColorBySiteThemeColor('textOnBrand'),
    },
    promo: {
        bgColor: color('surfacePromo'),
        textColor: color('textOnBrand'),
        hoverTextColor: getHoverColorBySiteThemeColor('textOnBrand'),
    },
    transparent: {
        bgColor: 'transparent',
        textColor: color('textBrand'),
        hoverTextColor: getHoverColorBySiteThemeColor('textBrand'),
        activeTextColor: darken(0.02, '#6099FA'),
    },
    white: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('textBrand'),
        hoverTextColor: getHoverColorBySiteThemeColor('textBrand'),
        activeTextColor: darken(0.02, '#6099FA'),
    },
    whitePromo: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('textPromo'),
        hoverTextColor: getHoverColorBySiteThemeColor('textPromo'),
        activeTextColor: getHoverColorBySiteThemeColor('textPromo'),
    },
    whiteBlue: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('textBrand'),
        hoverTextColor: getHoverColorBySiteThemeColor('textBrand'),
        activeBgColor: darken(0.03, '#5e98df'),
        activeTextColor: '#fff',
    },
    whiteDanger: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('textError'),
        hoverTextColor: getHoverColorBySiteThemeColor('textError'),
    },
    whiteGreen: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('textSuccess'),
        hoverTextColor: getHoverColorBySiteThemeColor('textSuccess'),
        activeBgColor: darken(0.03, '#5e98df'),
        activeTextColor: '#fff',
    },
    transparentGreen: {
        bgColor: 'transparent',
        textColor: '#12c78c',
        borderColor: '#cdf3d8',
        hoverTextColor: lighten(0.02, '#12c78c'),
        hoverBorderColor: lighten(0.04, '#12c78c'),
        activeTextColor: darken(0.03, '#12c78c'),
    },
    transparentBlue: {
        bgColor: 'transparent',
        textColor: color('textOnBrand'),
        borderColor: '#d1e6fe',
        hoverTextColor: lighten(0.02, '#6099FA'),
        hoverBorderColor: lighten(0.04, '#6099FA'),
        activeTextColor: darken(0.03, '#6099FA'),
    },
    link: {
        bgColor: 'transparent',
        textColor: color('textBrand'),
        hoverTextColor: lighten(0.1, '#6099FA'),
        activeTextColor: darken(0.02, '#6099FA'),
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    packOfDay: {
        bgColor: 'transparent',
        textColor: '#fff',
        boxShadow: '0 0 rgba(255,255,255, 0.001)',
        borderColor: '#294577',
        hoverBgColor: '#5e98df',
        hoverTextColor: '#fff',
        hoverBorderColor: '#5e98df',
        activeBgColor: darken(0.03, '#5e98df'),
        activeTextColor: '#fff',
    },
    lightBlue: {
        bgColor: '#F6F8FB',
        textColor: '#6099FA',
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
        hoverBgColor: '#F6F8FB',
        hoverTextColor: lighten(0.02, '#6099FA'),
        activeBgColor: darken(0.03, '#F6F8FB'),
        activeTextColor: '#6099FA',
    },
    danger: {
        bgColor: color('surfaceBackgroundSecondary'),
        textColor: color('surfaceError'),
        hoverBgColor: color('surfaceError'),
        hoverTextColor: '#fff',
        hoverBorderColor: defaultTheme.colors.surfaceError,
        activeBorderColor: darken(0.03, defaultTheme.colors.surfaceError),
        activeBgColor: darken(0.03, defaultTheme.colors.surfaceError),
        activeTextColor: darken(0.03, '#fff'),
    },
};
function getButtonTheme(themeName, appTheme, withBorder) {
    const isObjectTheme = Object.keys(themes).indexOf(themeName) !== -1;
    return isObjectTheme
        ? createButtonTheme(themes[themeName], appTheme, withBorder)
        : createButtonTheme(themeName, appTheme, withBorder);
}
function createButtonTheme(buttonTheme, currentTheme, withBorder = false) {
    const isSimpleTheme = typeof buttonTheme === 'string';
    const themeBgColor = isSimpleTheme
        ? buttonTheme
        : buttonTheme.bgColor;
    const bgColor = isFunction(themeBgColor) ? themeBgColor({ theme: currentTheme }) : themeBgColor;
    const defaultHoverBgColor = lighten(0.04, bgColor);
    const defaultActiveBgColor = darken(0.03, bgColor);
    const defaultBorderColor = currentTheme.borders.button;
    // It is not style nor css object, it just style scheme
    const defaultTheme = {
        textColor: '#fff',
        hoverTextColor: '#fff',
        activeTextColor: '#fff',
        borderColor: defaultBorderColor,
        hoverBorderColor: defaultBorderColor,
        bgColor,
        hoverBgColor: defaultHoverBgColor,
        activeBgColor: defaultActiveBgColor,
        boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.1)',
        hoverBoxShadow: '0 0 15px 5px rgba(0, 56, 110, 0.08)',
        activeBoxShadow: 'inset 0 0 5px 5px rgba(0, 56, 110, 0.05)',
        withBorder,
        buttonTheme,
    };
    if (isSimpleTheme) {
        return defaultTheme;
    }
    return Object.assign(Object.assign({}, defaultTheme), buttonTheme);
}
export function hoverState({ buttonTheme, theme, withBorder }) {
    const { bgColor, hoverBgColor, hoverBorderColor, hoverTextColor, hoverBoxShadow, } = getButtonTheme(buttonTheme, theme, withBorder);
    const activeBoxShadows = [hoverBoxShadow];
    if (withBorder) {
        activeBoxShadows.push(`inset 0 0 0 1px ${hoverBorderColor}`);
    }
    const parsedHoverTextColor = hoverTextColor ? (typeof hoverTextColor === 'function' ? hoverTextColor({ theme }) : hoverTextColor) : '#fff';
    return css `
    box-shadow: ${activeBoxShadows.join(', ')};
    background-color: ${hoverBgColor || lighten(0.04, bgColor)};
    color: ${parsedHoverTextColor};
  `;
}
export function activeState({ buttonTheme, withBorder, theme }) {
    const { activeBgColor, activeBoxShadow, borderColor, activeTextColor, } = getButtonTheme(buttonTheme, theme, withBorder);
    const activeBoxShadows = [activeBoxShadow];
    if (withBorder) {
        activeBoxShadows.push(`inset 0 0 0 1px ${isFunction(borderColor) ? borderColor(theme) : borderColor}`);
    }
    return css `
    box-shadow: ${activeBoxShadows.join(', ')};
    background-color: ${activeBgColor};
    color: ${activeTextColor};
  `;
}
export const Button = (_a) => {
    var { as, buttonTheme, withBorder, children, stretch } = _a, props = __rest(_a, ["as", "buttonTheme", "withBorder", "children", "stretch"]);
    const appTheme = useTheme();
    const theme = getButtonTheme(buttonTheme, appTheme, withBorder);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(ButtonComponent, Object.assign({ buttonTheme: buttonTheme, withBorder: withBorder, stretch: stretch }, props, { as: as }), children)));
};
export const ButtonComponent = styled.button.withConfig({ displayName: "ButtonComponent", componentId: "sc-8sj8fi" }) `
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.214em;

  box-shadow: ${theme('boxShadow')};
  color: ${theme('textColor')};
  background-color: ${theme('bgColor')};

  ${ifProp('withBorder', css `
            box-shadow: ${p => p.theme.variant === 'accessible' ? theme('shadow.S') : theme('boxShadow')}, inset 0 0 0 1px ${theme('borderColor')};
          `)};

  &:not(:disabled) {
    &:hover {
      ${hoverState};
    }

    &:active {
      ${activeState};
    }
  }

  ${(props) => props.stretch
    ? css `
                    padding: 0.821em 0;
                    width: 100%;
                    max-width: 100%;
                  `
    : css `
                    padding: 0.821em 1.2em;
                    width: auto;
                  `};
  
  ${ifProp('isSmall', css `
    padding: ${zord(2, 3)};
  `)}

  border: 0;
  cursor: pointer;
  border-radius: 10px;
  text-decoration: none;

  font-weight: 500;
  text-align: center;

  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
  border-color 0.15s ease-in-out, color 0.15s;

  &[disabled] {
    cursor: default;
    background-color: ${color('surfaceDisabled')};
    color: #fff;
    box-shadow: none;
  }
`;
export const ButtonLink = (props) => (React.createElement(Button, Object.assign({}, props, { as: Link })));
ButtonLink.defaultProps = Button.defaultProps = {
    buttonTheme: 'green',
    withBorder: false,
    stretch: false,
};
const Loader = styled.div.withConfig({ displayName: "Loader", componentId: "sc-18xra8v" }) `
  display: flex;
  justify-content: center;
  height: 18px;
  align-items: center;
`;
const animation = keyframes `
  0%,
  80%,
  100% {
    opacity: 0.25;
  }

  40% {
    opacity: 1;
  }
`;
export const LoadingAnimation = styled.div.withConfig({ displayName: "LoadingAnimation", componentId: "sc-kbff2b" }) `
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: ${(props) => props.color || props.theme.colors.surfaceBackground};
  border-radius: 50%;
  animation: ${animation} 1s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }

  &:nth-child(2) {
    animation-delay: -0.15s;
  }
`;
export const ButtonLoader = ({ color = null, style }) => {
    return (React.createElement(Loader, { style: style },
        React.createElement(LoadingAnimation, { color: color }),
        React.createElement(LoadingAnimation, { color: color }),
        React.createElement(LoadingAnimation, { color: color })));
};
ButtonLoader.defaultProps = {
    style: {},
};
export const FormGroup = styled.div.withConfig({ displayName: "FormGroup", componentId: "sc-11c3w4k" }) `
  & + & {
    margin-top: ${zord(5)};
  }
`;
export const FormGroupSection = styled.div.withConfig({ displayName: "FormGroupSection", componentId: "sc-1oyfj6o" }) `
  padding: ${zord(4)};
  
  & + & {
    border-top: 1px solid ${color('surfaceLine')};
  }
`;
export const ButtonGroupDivider = styled.div.withConfig({ displayName: "ButtonGroupDivider", componentId: "sc-119t5h5" }) `
  margin: ${zord(4)} 0;
  height: ${ifProp('text', '16px', 0)};
  position: relative;
  text-align: center;
  background-color: ${color('surfaceBackgroundSecondary')};
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: calc(50% - 1px);
    height: 1px;
    background-color: ${color('surfaceBorder')};
  }

  ${ifProp('text', css `
            &:after {
              position: absolute;
              height: 16px;
              padding: 0 2px;
              font-weight: 500;
              font-size: 13px;
              content: "${prop('text')}";
              display: block;
              justify-content: center;
              background-color: ${color('surfaceBackgroundSecondary')};
              color: ${({ theme }) => theme.isDark ? theme.colors.surfaceSecondary : theme.colors.surfaceIcon};
            }
          `)};
`;
export const FormButton = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const { isSubmitting } = useFormikContext();
    return (React.createElement(Button, Object.assign({ buttonTheme: "blue", type: "submit", stretch: true, disabled: isSubmitting }, props), isSubmitting ? React.createElement(ButtonLoader, null) : children));
};
