import React from "react";
import PropTypes from "prop-types";
import { WhiteCard } from "../white-card";
import { TitleText } from "@socgress/ui/title-text";
import { IconList, ListShape } from "@socgress/ui/icon-list";
import { Container, Header, Body } from "./styled";
import styled from 'styled-components';
function ListJumbotron({ title, icon, list, bodyMaxWidth, children, lottieHideOverflow = false, lottieStyle = {}, containerStyle = {}, isRTL = false, }) {
    return (React.createElement(WhiteCard, { style: Object.assign({ overflow: "auto", overflowY: 'hidden' }, containerStyle) },
        React.createElement(Container, null,
            title && (React.createElement(Header, null,
                React.createElement(TitleText, null, title))),
            React.createElement(Body, { lottieHideOverflow: lottieHideOverflow, bodyMaxWidth: bodyMaxWidth },
                React.createElement("div", { style: { width: '100%' } },
                    React.createElement(IconList, { list: list, isRTL: isRTL }),
                    children),
                icon && (React.createElement(LottieContainer, { style: lottieStyle },
                    React.createElement("img", { src: icon })))))));
}
ListJumbotron.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    list: ListShape,
    children: PropTypes.node,
    bodyMaxWidth: PropTypes.number,
};
ListJumbotron.defaultProps = {
    image: null,
    list: [],
    children: null,
    bodyMaxWidth: 50,
};
export { ListJumbotron };
const LottieContainer = styled.div.withConfig({ displayName: "LottieContainer", componentId: "sc-1071901" }) `
  width: 150px;
  height: 150px;
  position: absolute;
  overflow: hidden;
  outline: none;
  margin: auto 0;
  top: 0;
  bottom: 0;
  
  img {
    width: 150px;
    height: 150px;
  }
  
  @media(max-width: 768px) {
    position: unset;
    right: 0;
  }
`;
