var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Link, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { theme } from "styled-tools";
const HeaderLink = (_a) => {
    var { children, href } = _a, props = __rest(_a, ["children", "href"]);
    const { pathname } = useLocation();
    const isActive = pathname === href;
    if (!href) {
        return (React.createElement(HeaderLinkContainer, Object.assign({ className: isActive ? "active" : null }, props), children));
    }
    if (props.as) {
        return (React.createElement(HeaderLinkContainer, Object.assign({ className: isActive ? "active" : null, href: href }, props), children));
    }
    return (React.createElement(HeaderLinkContainer, Object.assign({ className: isActive ? "active" : null, to: href }, props), children));
};
const AnchorHeaderLink = (_a) => {
    var { href, children } = _a, props = __rest(_a, ["href", "children"]);
    const { pathname } = useLocation();
    const isActive = pathname === href;
    return (React.createElement(HeaderLinkContainer, Object.assign({ to: href, className: isActive ? "active" : null }, props), children));
};
export { HeaderLink, AnchorHeaderLink };
const HeaderLinkContainer = styled(Link).withConfig({ displayName: "HeaderLinkContainer", componentId: "sc-q21p9s" }) `
  display: flex;
  align-items: center;

  padding: 0 12px;
  margin: 0 9px;
  height: 100%;

  &:first-of-type {
    margin-left: 0;
    padding-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  appearance: none;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;

  transition: color 0.15s;
  cursor: pointer;

  &:not(.active) {
    color: ${theme("header.linkColor")};
  }

  &.active,
  &:not(.active):hover {
    color: ${theme("header.activeLinkColor")};
  }
`;
