import React from 'react';
import styled from 'styled-components';
import { ifNotProp } from 'styled-tools';
import i18n from 'lib/i18n';
const emojiByLanguage = {
    ru: '🇷🇺',
    en: '🇺🇸',
};
function LocaleSelect({ isMarginDisabled }) {
    const { language } = i18n;
    const languageCode = language.split('-')[0].toLowerCase();
    const onLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };
    return (React.createElement(LocaleLabel, null,
        React.createElement(LanguageContainer, { isMarginDisabled: isMarginDisabled },
            React.createElement("span", { role: "img", style: {
                    fontSize: '20px',
                } }, emojiByLanguage[languageCode]),
            React.createElement(LanguageText, null, languageCode.toUpperCase()),
            React.createElement("img", { src: require('./dropdown-arrow.svg'), alt: "" })),
        React.createElement(HiddenLocaleSelect, { onChange: onLanguageChange, value: languageCode },
            React.createElement("option", { value: "ru" }, "Russian"),
            React.createElement("option", { value: "en" }, "English"))));
}
export { LocaleSelect };
const LanguageContainer = styled.div.withConfig({ displayName: "LanguageContainer", componentId: "sc-cp1lv" }) `
  display: flex;

  align-items: center;

  ${ifNotProp};
`;
const HiddenLocaleSelect = styled.select.withConfig({ displayName: "HiddenLocaleSelect", componentId: "sc-3y3ntu" }) `
  cursor: pointer;
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  -webkit-appearance: none;
`;
const LocaleLabel = styled.label.withConfig({ displayName: "LocaleLabel", componentId: "sc-mkhxnp" }) `
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
`;
const LanguageText = styled.p.withConfig({ displayName: "LanguageText", componentId: "sc-15fpaca" }) `
  font-size: 14px;
  color: white;
  margin: 0 6px;
`;
