import React from 'react';
import { hot } from 'react-hot-loader/root';
import styled from 'styled-components';
import { Header } from './header';
import { MainSection } from './main-section';
import { CountersSection } from './counters-section';
import { EarnSection } from './earn-section';
import { ServicesSection } from './services-section';
import { PanelLandingSection } from './panel-landing-section';
import { JoinSection } from './join-section';
import { Footer } from './footer';
const BotsLandingApp = () => (React.createElement(Container, null,
    React.createElement(Header, null),
    React.createElement(MainSection, null),
    React.createElement(CountersSection, null),
    React.createElement(EarnSection, null),
    React.createElement(ServicesSection, null),
    React.createElement(PanelLandingSection, null),
    React.createElement(JoinSection, null),
    React.createElement(Footer, null)));
export default hot(BotsLandingApp);
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-16tpoje" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Inter, Roboto, sans-serif;
  white-space: pre-wrap;
`;
