import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { color } from '@socgress/ui/themes';
import { zord } from '@socgress/lib/zord';
import { SmallText } from '@socgress/ui/small-text';
import { ifProp } from 'styled-tools';
import { CheckboxLabel } from '@socgress/ui/checkbox';
const SwitchMulti = ({ name, options, selected, onSelect }) => {
    const [selectedOption, setSelectedOption] = useState(selected || (options === null || options === void 0 ? void 0 : options[0].value));
    const onSelectHandler = (value) => {
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(value);
        setSelectedOption(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "text", hidden: true, name: name, value: selectedOption }),
        options.map((option) => (React.createElement(SwitchOption, { key: `switch-option-${option.value}`, selected: selectedOption === option.value },
            React.createElement(OptionContainer, { onClick: () => (selectedOption !== option.value) && onSelectHandler(option.value) },
                React.createElement(SmallText, null, option.label),
                React.createElement(CheckboxLabel, { borderRadius: 100 })),
            (option.renderAfter !== undefined) && (selectedOption === option.value) && (React.createElement(AfterContainer, null, option.renderAfter)))))));
};
export { SwitchMulti };
const SwitchOption = styled.div.withConfig({ displayName: "SwitchOption", componentId: "sc-givpno" }) `
  ${ifProp('selected', css `
    ${SmallText} {
      color: ${color('textMain')};
    }
    
    ${CheckboxLabel} {
      &:before {
        background-color: ${color('surfaceSuccess')};
        border-color: ${color('surfaceSuccess')};
      }
    }
  `)}
  
  & + & {
    border-top: 1px solid ${color('surfaceBorder')};
  }
`;
const OptionContainer = styled.div.withConfig({ displayName: "OptionContainer", componentId: "sc-i0tlvf" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${zord(3, 0)};
  cursor: pointer;
  
  ${SmallText} {
    transition: color 0.15s ease-in-out;
  }
  
  ${CheckboxLabel} {
    &:before, &:after {
      top: -9px;
    }
  }
`;
const AfterContainer = styled.div.withConfig({ displayName: "AfterContainer", componentId: "sc-biym07" }) `
  padding-bottom: ${zord(3)};
`;
