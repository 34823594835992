import { useEffect } from "react";
import { useFirstRun } from "@socgress/lib/hooks/useFirstRun";
function useEventListener(eventTarget, eventType, callback) {
    const isFirstRun = useFirstRun();
    useEffect(() => {
        if (!eventTarget || isFirstRun) {
            return;
        }
        const lazyEventTarget = typeof eventTarget === "function" ? eventTarget() : eventTarget;
        lazyEventTarget.addEventListener(eventType, callback);
        return () => {
            lazyEventTarget.removeEventListener(eventType, callback);
        };
    }, [isFirstRun, eventTarget, eventType, callback]);
}
function useWindowListener(type, callback) {
    useEventListener(() => (typeof window !== "undefined" ? window : null), type, callback);
}
export { useEventListener, useWindowListener };
