import styled, { css } from "styled-components";
import { color } from "./themes";
const titleTextStyle = css `
  font-weight: 500;
  font-size: 17px;
  line-height: 1.17;
  color: ${color("textMain")};
`;
const TitleText = styled.p.withConfig({ displayName: "TitleText", componentId: "sc-4ljf3s" }) `
  ${titleTextStyle};
`;
export { TitleText, titleTextStyle };
