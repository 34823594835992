import { Desktop, Tablet } from '@socgress/lib/media';
import React from 'react';
import styled from 'styled-components';
import { zord } from '@socgress/lib/zord';
import { useTranslation } from 'react-i18next';
import { color } from '@socgress/ui/themes';
import { LocaleSelect } from '../header/locale-select';
const Footer = () => {
    const [t] = useTranslation();
    const year = (new Date()).getFullYear();
    return (React.createElement(Container, null,
        React.createElement(InnerContainer, null,
            React.createElement(Desktop, null,
                React.createElement(MenuContainer, null,
                    React.createElement("a", { href: "mailto:socgressteam@gmail.com" }, t('footer.menu.contacts')),
                    React.createElement("a", { href: "https://t.me/socpanelflex", target: "_blank" }, t('footer.menu.blog'))),
                React.createElement(LogoContainer, null,
                    React.createElement(Logo, null),
                    "socpanel"),
                React.createElement(RightContainer, null,
                    React.createElement(LocaleSelect, null),
                    React.createElement(Copyright, null,
                        "\u00A9 ",
                        year))),
            React.createElement(Tablet, null,
                React.createElement(LogoContainer, null,
                    React.createElement(Logo, null),
                    "socpanel"),
                React.createElement(MenuContainer, null,
                    React.createElement("a", { href: "mailto:socgressteam@gmail.com" }, t('footer.menu.contacts')),
                    React.createElement("a", { href: "https://t.me/socpanelflex", target: "_blank" }, t('footer.menu.blog'))),
                React.createElement(RightContainer, null,
                    React.createElement(LocaleSelect, { isMarginDisabled: true }),
                    React.createElement(Copyright, null,
                        "\u00A9 ",
                        year))))));
};
export { Footer };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1usgy8e" }) `
  width: 100%;
  border-top: 1px solid #12191E;
`;
const InnerContainer = styled.div.withConfig({ displayName: "InnerContainer", componentId: "sc-1stj82h" }) `
  max-width: 1240px;
  margin: 0 auto;
  padding: ${zord(5)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 768px) {
    flex-direction: column;
  }
`;
const LogoContainer = styled.div.withConfig({ displayName: "LogoContainer", componentId: "sc-11tge5c" }) `
  display: flex;
  align-items: center;
  color: white;
`;
const Logo = styled.div.withConfig({ displayName: "Logo", componentId: "sc-1jph5dp" }) `
  width: 24px;
  height: 24px;
  margin-right: 8px;
  mask: url("${require('./static/logo.svg')}");
  background: white;
  font-family: 'Myriad Set Pro', sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 112%;

  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.02em;
`;
const MenuContainer = styled.div.withConfig({ displayName: "MenuContainer", componentId: "sc-1m3yd1k" }) `
  display: flex;
  align-items: center;
  gap: ${zord(7)};
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;

  > * {
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: ${color('textBrand')};
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
const RightContainer = styled.div.withConfig({ displayName: "RightContainer", componentId: "sc-1p4j0ag" }) `
  display: flex;
  align-items: center;
  gap: ${zord(7)};

  @media(max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
const Copyright = styled.span.withConfig({ displayName: "Copyright", componentId: "sc-mrn1gx" }) `
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #7E8FA5;
`;
