import styled, { css } from "styled-components";
import { color } from "./themes";
export const smallTextStyle = css `
  font-weight: 500;
  font-size: 13px;

  color: ${color("textSecondary")};
  white-space: pre-wrap;
`;
export const SmallText = styled.p.withConfig({ displayName: "SmallText", componentId: "sc-s9dud6" }) `
  ${smallTextStyle};
`;
