import React from 'react';
import styled from 'styled-components';
import { zord } from '@socgress/lib/zord';
import { SecondSubtitleText, SubtitleText, TitleButton, TitleText } from '../common/atoms';
import { useTranslation } from 'react-i18next';
import { EarnType } from './molecules';
const EarnSection = () => {
    const [t] = useTranslation();
    return (React.createElement(Container, null,
        React.createElement(TextContainer, null,
            React.createElement(TitleButton, null, t('earn.title_button')),
            React.createElement(TitleText, null, t('earn.title')),
            React.createElement(SubtitleText, null, t('earn.text'))),
        React.createElement(TypesContainer, null,
            React.createElement(EarnType, { type: "resell" }),
            React.createElement(EarnType, { type: "service" }),
            React.createElement(EarnType, { type: "resell_service" })),
        React.createElement(BottomInfo, null,
            React.createElement("img", { src: require('./static/bottom-info.svg'), alt: "" }),
            t('earn.bottom_info'))));
};
export { EarnSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-11q60au" }) `
  margin-top: 104px;
  padding: ${zord(0, 4)};
  display: flex;
  align-items: center;
  flex-direction: column;

  @media(max-width: 768px) {
    margin-top: 88px;
  }
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1yk992c" }) `
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
const TypesContainer = styled.div.withConfig({ displayName: "TypesContainer", componentId: "sc-10so4lz" }) `
  margin-top: ${zord(10)};
  min-width: 100%;

  @media(max-width: 768px) {
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
const BottomInfo = styled(SecondSubtitleText).withConfig({ displayName: "BottomInfo", componentId: "sc-wwyqmf" }) `
  margin-top: 65px;
  display: flex;
  align-items: center;

  > img {
    width: 20px;
    height: 20px;
    margin-right: ${zord(2)};
  }
`;
