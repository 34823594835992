import styled from "styled-components";
import { zord, Zord } from "@socgress/lib/zord";
const Icon = styled(Zord.withComponent("img")).withConfig({ displayName: "Icon", componentId: "sc-1i4re54" }) `
  display: block;
  width: 32px;
  height: 32px;
  object-fit: fill;
  object-position: center;
  margin-right: ${zord(4)};
`;
const SmallIcon = styled(Icon).withConfig({ displayName: "SmallIcon", componentId: "sc-v5pwbv" }) `
  width: 24px;
  height: 24px;
  margin: 0;
`;
export { Icon, SmallIcon };
