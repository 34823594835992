import styled, { css } from "styled-components";
import { color } from "./themes";
export const mediumTextStyle = css `
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  white-space: pre-wrap;

  color: ${color("textSecondary")};

  margin: 0;
`;
export const MediumText = styled.p.withConfig({ displayName: "MediumText", componentId: "sc-581c37" }) `
  ${mediumTextStyle};
`;
export const NormalText = styled.p.withConfig({ displayName: "NormalText", componentId: "sc-qvibbw" }) `
  ${mediumTextStyle};

  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
`;
