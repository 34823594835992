import React from 'react';
import styled from 'styled-components';
import { TitleText } from '../common';
import { useTranslation } from 'react-i18next';
import { zord } from '@socgress/lib/zord';
import { Button } from '@socgress/ui';
const JoinSection = () => {
    const [t] = useTranslation();
    const onJoin = () => window.location.href = '/register';
    return (React.createElement(Container, null,
        React.createElement(Logo, null),
        React.createElement(TitleText, null, t('join.title')),
        React.createElement(ButtonsContainer, null,
            React.createElement(StartButton, { buttonTheme: "blue", onClick: onJoin },
                t('join.start_button'),
                React.createElement(StartArrow, null)))));
};
export { JoinSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-c03ioo" }) `
  margin: 360px auto 220px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    margin: 70px 0 68px;
    width: 100%;
    padding: 0 15px;
  }
`;
const Logo = styled.div.withConfig({ displayName: "Logo", componentId: "sc-13sv2d3" }) `
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: ${zord(5)};
  background: url("${require('./static/logo.svg')}") no-repeat center center;

  &:after {
    display: block;
    position: absolute;
    left: calc(50% - 676px / 2);
    bottom: -130px;
    content: '';
    width: 676px;
    height: 506px;
    z-index: -1;
    background: url("${require('./static/glow.webp')}") no-repeat center center;
    background-size: cover;
  }
`;
const ButtonsContainer = styled.div.withConfig({ displayName: "ButtonsContainer", componentId: "sc-1orm1p" }) `
  margin-top: ${zord(8)};
  display: flex;
  gap: ${zord(3)};
  width: 100%;

  @media(max-width: 768px) {
    margin-top: 50px;

    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;
const StartArrow = styled.div.withConfig({ displayName: "StartArrow", componentId: "sc-y51aqb" }) `
  position: relative;
  margin-left: 9px;

  &:after {
    display: block;
    position: absolute;
    top: -6px;
    left: 0;
    width: 8px;
    height: 12px;
    content: '';
    background: url("${require('./static/arrow.svg')}") no-repeat center center;
    transition: all .2s ease-in-out;
  }
`;
const StartButton = styled(Button).withConfig({ displayName: "StartButton", componentId: "sc-euf2ou" }) `
  min-width: 180px;
  width: 100%;

  &:hover {
    > ${StartArrow}:after {
      left: 5px;
    }
  }
`;
