import React from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { color } from '@socgress/ui/themes';
const CopyableText = ({ children, container, copyableText, onCopy }) => {
    const BodyContainer = container || Container;
    return (React.createElement(CopyToClipboard, { text: copyableText || children, onCopy: onCopy },
        React.createElement(BodyContainer, null,
            children,
            React.createElement("div", null,
                React.createElement(CopyIcon, null)))));
};
export { CopyableText, CopyIcon };
const CopyIcon = styled.div.withConfig({ displayName: "CopyIcon", componentId: "sc-1e6u2lm" }) `
  width: 15px;
  height: 15px;
  mask: url('${require('./static/copy.svg')}');
  mask-size: contain;
  mask-repeat: no-repeat;
  margin-left: 10px;
  transition: background-color 200ms ease;

  background-color: ${color('surfaceIcon')};
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-iqufx1" }) `
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: color 200ms ease;
  
  &:hover {
    color: ${color('textBrand')};
    
    ${CopyIcon} {
      background-color: ${color('surfaceBrand')};
    }
  }
`;
