import styled from 'styled-components';
import { commonMedia } from '@socgress/lib/common-media';
import { WhiteCardStyle } from '@socgress/ui/white-card';
import { color } from '../../themes';
import { StyledLink } from '@socgress/ui/header/mobile-menu/menu-item';
import { prop } from 'styled-tools';
import { animated } from '@react-spring/web';
export const MobileMenuOverlay = styled(animated.div).attrs((props) => ({
    style: {
        visibility: props.isVisible ? 'visible' : 'hidden',
        opacity: props.isVisible ? 1 : 0,
    },
})).withConfig({ displayName: "MobileMenuOverlay", componentId: "sc-17zsx68" }) `
  z-index: 9999;
  position: fixed;
  max-height: ${prop('height')}px;

  left: 0;
  right: 0;
  bottom: -60px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  transform: translate3d(0, 0, 0);
  transition: opacity 0.2s, visibility 0.2s, max-height 200ms ease;
`;
export const MobileOverlay = styled.div.withConfig({ displayName: "MobileOverlay", componentId: "sc-att3b0" }) `
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  z-index: 102;
  background-color: rgba(11, 15, 41, 0.9);
`;
export const MenuGroup = styled.div.withConfig({ displayName: "MenuGroup", componentId: "sc-1st5cgn" }) `
  background-color: ${color('surfaceBackgroundSecondary')};

  ${commonMedia.greaterThan('tablet') `
    &:not(:last-of-type):after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid ${color('surfaceLine')}
    }
  `};

  ${commonMedia.lessThan('tablet') `
    &:not(:first-child) {
      margin: 20px 0;
    }
  `};
`;
export const DesktopMenuGroup = styled.div.withConfig({ displayName: "DesktopMenuGroup", componentId: "sc-1sgwjtc" }) `
  background-color: ${color('surfaceBackgroundSecondary')};

  ${commonMedia.lessThan('tablet') `
    &:not(:first-child) {
      margin: 20px 0;
    }
  `};
`;
export const CommonMenuWrapper = styled.div.withConfig({ displayName: "CommonMenuWrapper", componentId: "sc-1yegwg6" }) `
  background-color: ${color('surfaceBackground')};
  padding: 0 15px 75px;

  ${MenuGroup} {
    ${WhiteCardStyle}
    ${StyledLink} {
      background: none;

      &:after {
        display: none;
      }
    }
  }
`;
