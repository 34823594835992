import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { color } from '@socgress/ui/themes';
import { zord } from '@socgress/lib/zord';
import { MediumText } from '@socgress/ui';
import { ifProp, theme } from 'styled-tools';
const SwitchPane = ({ name, options, selected, onSelect }) => {
    const [selectedOption, setSelectedOption] = useState(options.find((option) => option.value === selected) || options[0]);
    const onSelectHandler = (value) => {
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(value.value);
        setSelectedOption(value);
    };
    const renders = options.reduce((accumulator, option) => (Object.assign(Object.assign({}, accumulator), { [option.value]: option.renderAfter })), {});
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "text", hidden: true, name: name, value: selectedOption.value }),
        React.createElement(OptionsContainer, null, options.map((option) => (React.createElement(SwitchOption, { key: `switch-option-${option.value}`, selected: selectedOption.value === option.value },
            React.createElement(MediumText, null, option.label),
            React.createElement(OptionContainer, { onClick: () => (selectedOption.value !== option.value) && onSelectHandler(option) }, option.pane))))),
        (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.renderAfter) && (React.createElement(React.Fragment, null, renders[selectedOption.value]))));
};
export { SwitchPane };
const OptionsContainer = styled.div.withConfig({ displayName: "OptionsContainer", componentId: "sc-xw2zeu" }) `
  display: flex;
  gap: ${zord(4)};
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: end;
  }
`;
const OptionContainer = styled.div.withConfig({ displayName: "OptionContainer", componentId: "sc-1uwis7b" }) `
  margin-top: ${zord(2)};
  cursor: pointer;
  transition: border-color .15s ease-in-out;
  border-radius: 10px;
  border: 1px solid ${color('surfaceBorder')};
  background: ${color('surfaceBackground')};
  box-shadow: ${theme('shadows.M')};
`;
const SwitchOption = styled.div.withConfig({ displayName: "SwitchOption", componentId: "sc-1vf3fh8" }) `
  flex: 1;
  text-align: center;
  
  ${MediumText} {
    transition: color .15s ease-in-out;
  }
  
  ${ifProp('selected', css `
    ${MediumText} {
      color: ${color('textMain')};
    }
    
    ${OptionContainer} {
      border: 3.27px solid ${color('surfaceBrand')};
      
      > * {
        border-radius: 7px;
      }
    }
  `)}
`;
