import { useIsTablet } from '@socgress/lib/common-media';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TitleText } from '../common';
import { zord } from '@socgress/lib/zord';
import { ArrowLink, SecondSubtitleText, SecondTitleText, TitleButton } from '../common/atoms';
import { ifProp } from 'styled-tools';
const ServicesSection = () => {
    const [t] = useTranslation();
    const isTablet = useIsTablet();
    return (React.createElement(Container, null,
        React.createElement(TitleText, null, t('services.title')),
        ['search', 'list'].map((service) => (React.createElement(ServiceContainer, null,
            React.createElement(SecondTitleText, null, t(`services.services.${service}.title`)),
            React.createElement(ServiceSubtitle, null, t(`services.services.${service}.text`)),
            React.createElement(ImageContainer, { type: service },
                React.createElement("img", { src: isTablet ? require(`./static/${service}-mobile@2x.webp`) : require(`./static/${service}@2x.webp`), alt: "" })),
            React.createElement(BottomContainer, null,
                React.createElement(LeftFeatureHint, { type: service },
                    React.createElement("img", { src: require(`./static/${service}-info.svg`), alt: "" }),
                    t(`services.services.${service}.info`)),
                React.createElement(ArrowLink, { href: t(`services.services.${service}.link`), target: "_blank" }, t(`services.services.${service}.button`))))))));
};
export { ServicesSection };
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-zgg1us" }) `
  margin-top: calc(220px - 32px);
  padding-top: 32px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: url('${require('./static/lines.svg')}') no-repeat center calc(15px + 32px),
    url('${require('./static/glow.webp')}') no-repeat center calc(-120px + 32px);

  @media(max-width: 768px) {
    padding: 0 15px;
    margin-top: 104px;
  }
`;
const ServiceContainer = styled.div.withConfig({ displayName: "ServiceContainer", componentId: "sc-1pe3wto" }) `
  margin-top: 64px;
  text-align: left;

  @media(max-width: 768px) {
    margin-top: 50px;
  }
`;
const ServiceSubtitle = styled(SecondSubtitleText).withConfig({ displayName: "ServiceSubtitle", componentId: "sc-1kcax9k" }) `
  margin-top: ${zord(2)};
`;
const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-174gye7" }) `
  max-width: 808px;
  margin-top: ${zord(5)};

  ${ifProp({ type: 'list' }, css `
    position: relative;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: url('${require('./static/glow2.webp')}') no-repeat center;
      background-size: cover;
      top: -45%;
      z-index: -1;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      background: url('${require('./static/list-popover@2x.webp')}') no-repeat top center;
      background-size: contain;
      width: 160px;
      height: 160px;
      transform: rotate(-11.82deg);
      bottom: 45px;
      right: 100px;
    }
  `)};

  > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
const BottomContainer = styled.div.withConfig({ displayName: "BottomContainer", componentId: "sc-ogldcq" }) `
  margin-top: ${zord(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftFeatureHint = styled(TitleButton).withConfig({ displayName: "LeftFeatureHint", componentId: "sc-1wmyh6y" }) `
  margin-bottom: 0;

  ${ifProp({ type: 'search' }, css `
    background: linear-gradient(90.21deg, #B2ACFA 10%, #DFCCEF 59.53%, #FFE2E7 95.06%);
  `)}
  ${ifProp({ type: 'list' }, css `
    background: linear-gradient(90.21deg, #F8ACFA 10%, #F0AFBF 58.73%, #FFE2E7 95.06%);
  `)}

  > img {
    width: 16px;
    height: 16px;
    margin-right: ${zord(2)};
  }

  @media(max-width: 768px) {
    display: none;
  }
`;
