import styled, { css } from "styled-components";
import { color } from "../themes";
import { ifProp } from 'styled-tools';
export const List = styled.ul.withConfig({ displayName: "List", componentId: "sc-1h5xvz0" }) `
  list-style: none;
`;
export const Item = styled.li.withConfig({ displayName: "Item", componentId: "sc-tf84xn" }) `
  position: relative;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: pre-wrap;
  color: ${color("textSecondary")};

  &:not(:first-child) {
    margin-top: 14px;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 24px;
    height: 24px;
    background: url(${(props) => props.icon}) no-repeat;
    background-size: contain;
  }
  
  ${ifProp('isRTL', css `
    padding-right: 39px;
    
    &:before {
      display: none;
    }
    &:after {
      left: unset;
      right: 0;
    }
  `, css `
    padding-left: 39px;
    
    &:after {
      display: none;
    }
    &:before {
      left: 0;
      right: unset;
    }
  `)}
`;
