import { useBodyScrollLock } from "@socgress/lib/hooks/useBodyScrollLock";
export { useBodyScrollLock };
// export { useDebounce } from "./useDebounce";
import { usePrevious } from "./usePrevious";
import { useEventListener, useWindowListener } from "./useEventListener";
export { useInterval } from "./useInterval";
import { useOnClickOutside } from "@socgress/lib/hooks/useOnClickOutside";
// export { useUpdateLog } from "./useUpdateLog";
// export { useSearch } from "./useSearch";
export { useSwitchBack } from "./useSwitchBack";
// export { useEcho } from "./useEcho";
// export { useLocalStorage } from "./useLocalStorage";
export { usePending } from "./usePending";
export { useScrolling } from './use-scrolling';
export { useHover } from './use-hover';
import { useSearchQuery } from "./useSearchQuery";
import { useFirstRun } from "@socgress/lib/hooks/useFirstRun";
import { useResponsive } from "./useResponsive";
import { useDebounce } from "./useDebounce";
const isDevelopment = process.env.NODE_ENV !== 'production';
const isProduction = process.env.NODE_ENV === 'production';
const isSocpanelHost = ['socpanel.com', 'socpanel.local:8080', 'socpanel.local:8081', 'dev.socpanel.com', 'socpanel.local', '0.0.0.0:8081'].includes(window.location.host);
export { usePrevious, useEventListener, useOnClickOutside, useSearchQuery, useFirstRun, useResponsive, useWindowListener, useDebounce, isDevelopment, isProduction, isSocpanelHost, };
