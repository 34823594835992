import { useIsTablet } from '@socgress/lib/common-media';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, switchProp } from 'styled-tools';
import { ArrowLink as ArrowLinkBase, SecondSubtitleText, SecondTitleText, TitleButton } from '../../common/atoms';
import { useTranslation } from 'react-i18next';
import { zord } from '@socgress/lib/zord';
const EarnType = ({ type }) => {
    const [t] = useTranslation();
    const isTablet = useIsTablet();
    const currentType = isTablet ? 'resell' : type;
    return (React.createElement(TypeContainer, { type: currentType, realType: type },
        React.createElement(HeadContainer, { type: currentType },
            React.createElement(HeadInnerContainer, null,
                React.createElement("div", null,
                    (type !== 'service') ? (React.createElement(Icon, { type: type })) : (React.createElement(IconButton, null,
                        React.createElement(IconButtonIcon, null),
                        React.createElement("span", null, "NEW"))),
                    React.createElement(TitleText, null, t(`earn.types.${type}.title`)),
                    React.createElement(SubtitleText, null, t(`earn.types.${type}.text`))),
                (type === 'resell_service') && (React.createElement(ArrowLink, { href: "https://socpanel.com", target: "_blank" }, t('earn.more_info'))))),
        React.createElement(ImageContainer, { type: type },
            React.createElement("img", { src: require(`./../static/${type}${isTablet ? '-mobile' : ''}.webp`), alt: "" }))));
};
export { EarnType };
const TypeContainer = styled.div.withConfig({ displayName: "TypeContainer", componentId: "sc-1h8i1i0" }) `
  position: relative;
  margin-top: ${switchProp('type', {
    resell: '0',
    service: '0',
    resell_service: '120px',
})};

  ${ifProp({ type: 'resell_service' }, css `
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    > div:last-of-type {
      max-width: 539px;
    }
  `)}

  ${ifProp({ type: 'service' }, css `
    > div:last-of-type {
      margin-top: 18px;
    }
  `)};

  @media(max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
const HeadContainer = styled.div.withConfig({ displayName: "HeadContainer", componentId: "sc-1hr29ft" }) `
  display: flex;
  justify-content: ${switchProp('type', {
    resell: 'flex-start',
    service: 'center',
    resell_service: 'flex-end',
})};
`;
const HeadInnerContainer = styled.div.withConfig({ displayName: "HeadInnerContainer", componentId: "sc-uxled8" }) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  z-index: 11;
`;
const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-70xqwg" }) `
  width: 24px;
  height: 24px;
  background: url('${(props) => require(`./../static/${props.type}-icon.svg`)}') no-repeat center center;
`;
const IconButton = styled(TitleButton).withConfig({ displayName: "IconButton", componentId: "sc-1jklknf" }) `
  background: linear-gradient(90.21deg, #85C0F6 10%, #EEFAFF 95.06%);
  padding: 6px 11px 5px 7px;
  line-height: 120%;

  @media(max-width: 768px) {
    margin-bottom: 0;
  }
`;
const IconButtonIcon = styled.div.withConfig({ displayName: "IconButtonIcon", componentId: "sc-124vnxp" }) `
  width: 14px;
  height: 14px;
  margin-right: ${zord(2)};
  background: url('${require('./../static/service-icon.svg')}') no-repeat center center;
`;
const TitleText = styled(SecondTitleText).withConfig({ displayName: "TitleText", componentId: "sc-f31t4s" }) `
  margin-top: ${zord(5)};

  @media(max-width: 768px) {
    margin-top: 15px;
  }
`;
const SubtitleText = styled(SecondSubtitleText).withConfig({ displayName: "SubtitleText", componentId: "sc-1n6h59e" }) `
  margin-top: ${zord(3)};
`;
const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-1h66dxo" }) `
  max-width: 975px;
  z-index: 1;

  margin-top: ${switchProp('type', {
    resell: -200,
    service: -145,
    resell_service: 0,
})}px !important;

  > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @media(max-width: 768px) {
    margin-top: ${switchProp('type', {
    resell: -160,
    service: -155,
    resell_service: -250,
})}px !important;
  }
`;
const ArrowLink = styled(ArrowLinkBase).withConfig({ displayName: "ArrowLink", componentId: "sc-191srzo" }) `
  margin-bottom: 60px;
`;
