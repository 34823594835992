import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { formatNumber } from '@socgress/lib/helpers';
import capitalize from 'lodash/capitalize';
import { en, ru } from './resources';
const defaultLngs = ['ru', 'en', 'uk'];
export const defaultLngFallback = {
    'ru-ua': 'uk',
    be: 'ru',
    kk: 'ru',
};
export const getLanguageCode = (language, languages = defaultLngs, fallback = defaultLngFallback) => {
    const langKey = language.toLowerCase();
    if (languages.includes(langKey)) {
        return langKey;
    }
    const fallbackFound = fallback[langKey];
    if (fallbackFound) {
        return fallbackFound;
    }
    if (langKey.length > 2) {
        return getLanguageCode(langKey.slice(0, 2));
    }
    return 'en'; // default fallback
};
// // hack to force ru language
// const routeDetector = {
//   name: 'custom-route',
//   lookup: () => {
//     if (window.location.hostname === 'socgress.com') {
//       return undefined;
//     }
//
//     if (window.location.pathname.indexOf('/order') !== 0) {
//       return 'ru';
//     }
//   },
// };
//
// const domainDetector = {
//   name: 'custom-domain',
//   lookup: () => {
//     if (!window) {
//       return null;
//     }
//
//     switch (window.location.hostname) {
//       case 'socgress.com': {
//         return undefined;
//       }
//       case 'test.socgress.com':
//       case 'old.socgress.net': {
//         return 'en';
//       }
//       default: {
//         return 'ru';
//       }
//     }
//   },
// };
const detector = new LngDetector();
// detector.addDetector(domainDetector);
// detector.addDetector(routeDetector);
function updateNumeralLocale(lang) {
    const langCode = getLanguageCode(lang);
    const numeralFallback = {
        uk: 'uk-ua',
    };
    const hasFallback = numeralFallback[langCode] !== undefined;
    // eslint-disable-next-line no-nested-ternary
    const foundLang = hasFallback ? numeralFallback[langCode] : langCode;
    import('numeral').then(numeral => {
        if (foundLang === 'en') {
            return numeral.locale('en');
        }
        return import(`numeral/locales/${foundLang}`).then(() => {
            numeral.locale(foundLang);
        });
    });
}
i18n.on('languageChanged', lang => {
    const langCode = getLanguageCode(lang); // [ru, en, uk]
    updateNumeralLocale(langCode);
});
// @ts-ignore
i18n.use(detector).init({
    detection: {
        order: ['querystring', 'localStorage', 'navigator'],
        lookupQuerystring: 'lng',
        lookupLocalStorage: 'soc-i18n',
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
    },
    interpolation: {
        escapeValue: false,
        format: (value, format) => {
            if (format === 'formatPrice')
                return formatNumber(value);
            if (format === 'formatInteger')
                return formatNumber(value);
            if (format === 'capitalize')
                return capitalize(value);
            return value;
        },
    },
    resources: {
        en: {
            common: en,
        },
        ru: {
            common: ru,
        },
    },
    fallbackLng: {
        default: ['en'],
    },
    ns: ['common', 'error', 'auth', 'balance', 'cpa', 'payModal', 'toasts'],
    defaultNS: 'common',
    debug: process.env.NODE_ENV !== 'production',
    react: {
        useSuspense: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'fallback',
    },
});
export default i18n;
