import styled from "styled-components";
import CoinIcon from "./soccoin.svg";
import { color } from "../themes";
const Coin = styled.img.attrs((props) => ({
    style: {
        width: `${props.size}px`,
        height: `${props.size}px`,
        marginRight: `${props.marginRight || props.size / 2}px`,
    },
})).withConfig({ displayName: "Coin", componentId: "sc-19mjdie" }) `
  display: block;
  user-select: none;
  color: ${color("surfaceAttention")};
  object-fit: contain;
`;
Coin.defaultProps = {
    src: CoinIcon,
    alt: "Socgress Coin",
};
export { Coin };
