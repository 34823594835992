import React from 'react';
import styled from 'styled-components';
function CustomPanelLogo({ logo }) {
    return (React.createElement(PanelLogo, { src: logo, alt: "" }));
}
export { CustomPanelLogo };
const PanelLogo = styled.img.withConfig({ displayName: "PanelLogo", componentId: "sc-v7cj87" }) `
  max-width: 200px;
  max-height: 40px;
`;
