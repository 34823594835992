import styled from "styled-components";
const sizeToPx = [0, 2, 5, 10, 15, 18, 25, 30, 50, 70, 95, 135];
/*
  Our margin padding system
  1 => 2
  2 => 5
  3 => 10
  4 => 15
  5 => 18
  6 => 25
  7 => 30
  8 => 50
  9 => 70
  10 => 95
  11 => 135
 */
const getSizeValue = (size, currentSizeToPx) => {
    if (size === "auto") {
        return size;
    }
    let sign = '';
    if (size < 0) {
        size = Math.abs(size);
        sign = '-';
    }
    return `${sign + currentSizeToPx[size]}px`;
};
const getZordCssValue = (size = 0, currentSizeToPx = sizeToPx) => {
    return Array.isArray(size)
        ? size.map(currentSize => getSizeValue(currentSize, currentSizeToPx)).join(" ")
        : getSizeValue(size, currentSizeToPx);
};
const Zord = styled.div.attrs(({ marginZord, paddingZord, style, theme }) => ({
    style: {
        margin: getZordCssValue(marginZord, theme.zord || sizeToPx),
        padding: getZordCssValue(paddingZord, theme.zord || sizeToPx),
        ...style,
    },
})).withConfig({ displayName: "Zord", componentId: "sc-1irx3wx" }) ``;
const zord = (...size) => {
    return ({ theme }) => {
        if (size.length >= 1 && Array.isArray(size[0])) {
            return getZordCssValue(size[0], theme.zord || sizeToPx);
        }
        else {
            return getZordCssValue(size, theme.zord || sizeToPx);
        }
    };
};
export { Zord, zord };
