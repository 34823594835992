import { useEffect } from "react";
import { useFirstRun } from "@socgress/lib/hooks/useFirstRun";
function useOnClickOutside(ref, onClickOutside) {
    const isFirstRun = useFirstRun();
    useEffect(() => {
        if (isFirstRun) {
            return;
        }
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            onClickOutside(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, onClickOutside, isFirstRun]);
}
export { useOnClickOutside };
