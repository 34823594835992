import { generateMedia } from "styled-media-query";
import { useMediaQuery } from "react-responsive";
const BREAKPOINTS = {
    LARGE: 1224,
    DESKTOP: 900,
    TABLET: 768,
    MOBILE: 540,
    SMALL: 320,
};
const medias = {
    large: `${BREAKPOINTS.LARGE}px`,
    desktop: `${BREAKPOINTS.DESKTOP}px`,
    tablet: `${BREAKPOINTS.TABLET}px`,
    mobile: `${BREAKPOINTS.MOBILE}px`,
    small: `${BREAKPOINTS.SMALL}px`,
};
// for using in styled
const commonMedia = generateMedia(medias);
export { commonMedia };
// hooks, and devices for SSR
const useIsDesktop = (device = null) => useMediaQuery({ minWidth: BREAKPOINTS.TABLET + 1 }, device);
const useIsTablet = (device = null) => useMediaQuery({ maxWidth: BREAKPOINTS.TABLET }, device);
const desktopDevice = {
    width: BREAKPOINTS.TABLET + 1,
};
const tabletDevice = {
    width: BREAKPOINTS.TABLET,
};
const useIsLighthouse = () => window.navigator.userAgent.match(/Chrome-Lighthouse/i);
export { useIsDesktop, desktopDevice, useIsTablet, tabletDevice, useIsLighthouse };
