import React from "react";
import styled from "styled-components";
import { titleTextStyle } from "./title-text";
import { prop } from 'styled-tools';
function Brand({ logo: Logo, brandName, logoMaxHeight }) {
    const logo = !Logo ? null : typeof Logo === "string" ? (React.createElement(LogoImg, { logoMaxHeight: logoMaxHeight, src: Logo })) : (React.createElement(Logo, null));
    return (React.createElement(Container, null,
        logo,
        brandName && React.createElement(BrandName, null, brandName)));
}
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-i0te3h" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  ${titleTextStyle};
`;
const LogoImg = styled.img.withConfig({ displayName: "LogoImg", componentId: "sc-1jhoi3s" }) `
  object-fit: fill;
  max-width: 100%;
  max-height: ${prop('logoMaxHeight', 30)}px;
  
  @media(max-width: 768px) {
    max-height: ${p => (p.logoMaxHeight * 0.8 || 30)}px;
  }
`;
const BrandName = styled.p.withConfig({ displayName: "BrandName", componentId: "sc-15vrgc3" }) `
  margin-left: 5px;
`;
export { Brand };
