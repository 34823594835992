import { creamTheme } from './cream';
import { defaultTheme, dashboardTheme } from './default';
import { grassyTheme } from './grassy';
import { greenTheme } from './green';
import { lightBlueTheme } from './light-blue';
import { lightBrownTheme } from './light-brown';
import { lightGreenTheme } from './light-green';
import { lightPurpleTheme } from './light-purple';
import { lightRedTheme } from './light-red';
import { lightVioletTheme } from './light-violet';
import { nightTheme } from './night';
import { darkOrangeTheme } from './dark-orange';
import { blackTheme } from './black';
import { darkGreenTheme } from './dark-green';
import { darkVioletTheme } from './dark-violet';
import { mintTheme } from './mint';
import { grayRedTheme } from './gray-red';
import { lightPinkTheme } from './light-pink';
import { sunTheme } from './sun';
export const availableThemes = {
    default: defaultTheme,
    classic_dark: nightTheme,
    dark_green: darkGreenTheme,
    dark_orange: darkOrangeTheme,
    dark_violet: darkVioletTheme,
    pink: lightPinkTheme,
    mint: mintTheme,
    gray_red: grayRedTheme,
    black: blackTheme,
    cream: creamTheme,
    light_green: lightGreenTheme,
    light_blue: lightBlueTheme,
    light_purple: lightPurpleTheme,
    light_red: lightRedTheme,
    light_brown: lightBrownTheme,
    green: greenTheme,
    sun: sunTheme,
    light_violet: lightVioletTheme,
    grassy: grassyTheme,
};
export { defaultTheme, dashboardTheme, nightTheme, darkGreenTheme, darkOrangeTheme, darkVioletTheme, blackTheme, mintTheme, grayRedTheme, lightPinkTheme, creamTheme, lightGreenTheme, lightBlueTheme, lightPurpleTheme, lightRedTheme, greenTheme, sunTheme, lightBrownTheme, lightVioletTheme, grassyTheme, };
/* export function color(
  name: keyof Colors
): <Props, Theme extends { [key: string]: any }>(
  props: Props & {
    theme: Theme & {
      colors: Colors;
    };
  }
) => Color; */
export function color(name) {
    return ({ theme }) => {
        return theme.colors[name];
    };
}
export function borderRadius(defaultBorder = 10) {
    return ({ theme }) => {
        return typeof theme.borderRadius === 'undefined' ? defaultBorder : theme.borderRadius;
    };
}
