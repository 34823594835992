import { rgba } from "polished";
const colors = {
    surfaceError: '#FB695E',
    textError: '#FB695E',
    surfacePromo: '#FF7438',
    textPromo: '#FF7438',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#32C74B',
    textBrand: '#32C74B',
    textOnBrand: '#FFFFFF',
    surfaceSuccess: '#12C78C',
    textSuccess: '#12C78C',
    surfaceLine: '#262240',
    textMain: '#FFFFFF',
    textSecondary: '#C5C4CE',
    surfaceIcon: '#6C6888',
    surfaceBorder: '#363252',
    surfaceBackground: '#07041C',
    surfaceBackgroundSecondary: '#1A172E',
    surfaceBackgroundAlternate: rgba('#07041C', 0.5),
    surfaceDisabled: '#383965',
};
const backgrounds = {
    lightGrey: colors.surfaceBackgroundSecondary,
    checkbox: colors.surfaceBackgroundSecondary,
    input: colors.surfaceBackgroundSecondary,
    mobileButton: colors.surfaceBackgroundSecondary
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: colors.surfaceBorder,
    whiteBlueButton: colors.surfaceBorder,
};
const defaultShadow = {
    S: `0px 1px 1px rgba(0, 56, 110, 0.08)`,
    M: '0 5px 15px rgba(0, 0, 0, 0.2)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: colors.surfaceBorder,
    backgroundColor: colors.surfaceBackgroundSecondary,
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const darkGreenTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#07041C',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, darkGreenTheme), { header: whiteHeader });
