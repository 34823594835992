import { rgba } from "polished";
const colors = {
    surfaceError: '#FB695E',
    textError: '#FB695E',
    surfacePromo: '#FF7438',
    textPromo: '#fb695e',
    surfaceAttention: '#ffc000',
    textAttention: '#ffc000',
    surfaceBrand: '#FFFFFF',
    textBrand: '#FFFFFF',
    textOnBrand: '#000000',
    surfaceSuccess: '#12C78C',
    textSuccess: '#12C78C',
    surfaceLine: '#2E2E2E',
    textMain: '#FFFFFF',
    textSecondary: '#999999',
    surfaceIcon: '#808080',
    surfaceBorder: '#3D3D3D',
    surfaceBackground: '#000000',
    surfaceBackgroundSecondary: '#1F1F1F',
    surfaceBackgroundAlternate: rgba('#000000', 0.5),
    surfaceDisabled: '#3D3D3D',
};
const backgrounds = {
    lightGrey: colors.surfaceBackgroundSecondary,
    checkbox: colors.surfaceBackgroundSecondary,
    input: colors.surfaceBackgroundSecondary,
    mobileButton: colors.surfaceBackgroundSecondary
};
const borders = {
    button: colors.surfaceBorder,
    checkbox: colors.surfaceBorder,
    whiteBlueButton: colors.surfaceBorder,
};
const defaultShadow = {
    S: `0px 1px 1px rgba(0, 56, 110, 0.08)`,
    M: '0 5px 15px rgba(0, 0, 0, 0.2)',
    L: '0px 10px 30px rgba(0, 0, 0, 0.2)',
};
const defaultHeader = {
    bgColor: colors.surfaceBackground,
    linkColor: colors.surfaceBorder,
    activeLinkColor: colors.surfaceBackground,
    menuButtonColor: colors.surfaceBackground,
    activeMenuButtonColor: colors.surfaceBackground,
    desktopMenuBg: colors.surfaceBackground,
    boxShadow: defaultShadow.S,
};
const defaultPopup = {
    closeColor: '#AEB9C7',
    borderColor: colors.surfaceBorder,
    backgroundColor: colors.surfaceBackgroundSecondary,
};
const whiteHeader = Object.assign(Object.assign({}, defaultHeader), { bgColor: colors.surfaceBackgroundSecondary, linkColor: colors.textSecondary, activeLinkColor: colors.textMain, menuButtonColor: colors.surfaceBrand, activeMenuButtonColor: colors.surfaceBrand, desktopMenuBg: colors.surfaceBackground });
export const blackTheme = {
    colors,
    backgrounds,
    borders,
    loginBackground: '#000000',
    popup: defaultPopup,
    shadow: defaultShadow,
    header: whiteHeader,
    globalBackground: colors.surfaceBackground,
    mixins: {
        maxContentWidth: "1224px",
    },
};
export const dashboardTheme = Object.assign(Object.assign({}, blackTheme), { header: whiteHeader });
