import styled from "styled-components";
import { color } from "./themes";
import { Zord } from "@socgress/lib/zord";
const BlueLink = styled(Zord.withComponent("a")).withConfig({ displayName: "BlueLink", componentId: "sc-yu8dig" }) `
  display: inline-block;
  appearance: none;
  text-decoration: none;
  color: ${color("surfaceBrand")};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;
export { BlueLink };
